












































































import { CustomDate } from "@/mixins/custom-date";
import { Company } from "@/models/company.interface";
import { PenaltyUser } from "@/models/penalty.interface";
import { User } from "@/models/user.interface";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({})
export default class PenaltyCard extends mixins(CustomDate) {
  @Prop({ required: true }) penalty!: PenaltyUser;
  @Prop({ default: "30rem" }) width!: string | number;
  get contacts() {
    const subject: User | Company =
      this.penalty.admin || this.$store.getters["system/getCompany"];
    return {
      phone: subject.phone,
      email: subject.email,
    };
  }
}
