







































import { FormValidations } from "@/mixins/form-validations";
import { Notification } from "@/models/notification.interface";
import Component, { mixins } from "vue-class-component";
import { StyleCheck } from "@/mixins/style-check";
import { Prop, PropSync, Watch } from "vue-property-decorator";

@Component({})
export default class AliasChecker extends mixins(FormValidations, StyleCheck) {
  @Prop({ default: false })
  disabled!: boolean;
  @PropSync("alias", { type: String }) syncedAlias!: string;
  @PropSync("invalid", { type: Boolean }) syncedInvalid!: boolean;
  loading = false;

  aliasTimer = 0;
  @Watch("syncedAlias")
  async verifyAlias(value: string) {
    if (this.syncedAlias) {
      this.loading = true;
      clearTimeout(this.aliasTimer);
      this.aliasTimer = setTimeout(async () => {
        let exists = await this.$store
          .dispatch("authentication/userExists", {
            email: "",
            alias: value,
          })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("SignUp.fetchError.aliasExists"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });
        this.syncedInvalid = exists;
        this.loading = false;
      }, 500);
    }
  }
}
